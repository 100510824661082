<template>
    <v-row no-gutters>
      <v-col cols="12" class="pa-0">
        <skeleton-list v-if="loading" />
        <template v-else>
          <v-empty-state v-if="!logList.length" :isFree="true" title="No existen LOGS para mostrar" type="log" id="LOGS" customClass="mt-3">
          <!-- <template v-slot:actions>
            <v-btn @click="$emit('activeBtnFilter')" color="blue-500" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
            <v-btn class="ml-2" @click="setSort('-created')" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
          </template> -->
        </v-empty-state>
          <!-- <v-empty-state v-if="!logList.length" title="No existen LOGS para mostrar" customClass="mt-n6" :height="env ? 320 : 200" :outlined="false" type="log" /> -->
          <template v-else>
            <v-data-table class="spaced-table table-not-rounded" :class="{'fixed-table' : $store.state.base.isExpandNavigationDrawer}" :headers="headers" :items="logList" :items-per-page="pagination" hide-default-header disable-sort mobile-breakpoint hide-default-footer item-key="id" v-model="selected" show-select checkbox-color="blue-500" fixed-header :height="heightTable">
              <!-- header -->
              <template v-slot:header="{ props: { headers } }">
                <VTableHeaders :headers="headers" @sort="setSort" @toggleAll="toggleAll" @isSelected="isSelected = $event" :resetCheckbox="resetCheckbox" />
                <v-divider style="position: absolute; z-index: 2; margin-top: -6px; margin-left: -6px; min-width: calc(100% - 7px)" />
              </template>
              <!-- end header -->
              <template v-slot:[`item.title`]="{item}">
                <span class="d-inline-block text-truncate" style="max-width: 95%;">{{(item || {}).title}}</span>
              </template>
              <template v-slot:[`item.created`]="{item}">{{item.created | dateTime}}</template>
              <template v-slot:[`item.date`]="{item}">{{item.date | dateTime}}</template>
              <template v-slot:[`item.reviewed`]="{item}">{{ !item.reviewed ? 'Resuelto' : 'Activo' }}</template>
              <template v-slot:[`item.pos`]="{item}">{{item.pos.name || '-'}}</template>
              <template v-slot:[`item.branch`]="{item}">{{item.branch.name || '-'}}</template>
              <template v-slot:[`item.event_type`]="{item}">
                <span class="d-inline-block text-truncate" style="max-width: 95%;">{{nameEventType(item.event_type)}}</span>
              </template>
            </v-data-table>
            <VMainPagination titleShowAll="Ver todas las compras" :count="logList.length" redirect="logList" />
          </template>
        </template>
        <!-- dialog send log -->
        <v-dialog v-model="dialogSendEmail" width="700" persistent no-click-animation overlay-color="grey-500">
          <v-card>
            <v-card-title class="pa-0">
              <v-toolbar class="transparent" flat height="46">
                <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Enviar LOG por email</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-row align="center" justify="center" class="mr-3">
                    <v-btn @click="$emit('closeSendEmail')" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
                  </v-row>
                </v-toolbar-items>
              </v-toolbar>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-5 py-4">
              <v-list class="v-list-form pa-0 transparent">
              </v-list>
              <div class="d-flex align-center">
                <span class="grey-700--text body-1" style="min-width: 60px;">Para</span>
                <v-combobox class="a-combobox" ref="email" v-model="emails" :key="key" hide-details :items="emailsList" outlined required hide-selected :placeholder="!emails.length ? 'Escribe el email del destinatario' : ''" @update:search-input="setEmail"  multiple deletable-chips small-chips dense single-line autocomplete="off">
                  <template v-slot:selection="data">
                    <v-chip class="pr-2 ma-1" v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)" small>{{ data.item }}</v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                     <v-icon color="grey-500">mdi-account-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title class="ml-n2 font-weight-medium" v-html="data.item"></v-list-item-title>
                  </template>
                </v-combobox>
              </div>
            </v-card-text>
            <v-divider />
            <v-card-actions class="py-2 px-5">
              <v-spacer/>
              <v-btn outlined @click="$emit('closeSendEmail')">Cancelar</v-btn>
              <v-btn @click="sendEmails" color="blue-500">Continuar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- end dialog send log -->
      </v-col>
      <router-view />
    </v-row>
  </template>
  <script>
  import { mapState } from 'vuex'
  import { saveAs } from 'file-saver'
  import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
  import VEmptyState from '@/components/commons/VEmptyState'
  import RenderPdf from '@/components/files/RenderPdf'
  import VTableHeaders from '@/components/VTable/VTableHeaders'
  import ListViewMixin from '@/mixins/ListViewMixin'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import ChipStatus from '@/components/commons/ChipStatus'
  import VMainPagination from '@/components/commons/VMainPagination'

  const REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  export default {
    components: {
      ChipStatus,
      RenderPdf,
      VEmptyState,
      SkeletonList,
      VTableHeaders,
      VMainPagination
    },
    mixins: [
      ListViewMixin,
      GenericViewMixin,
      RenderViewMixin
    ],
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      activeDialogExport: {
        type: Boolean,
        default: false
      },
      dialogSendEmail: {
        type: Boolean,
        default: false
      },
      markSolved: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      key: 0,
      type: null,
      headers: [
        { text: 'Nombre', value: 'title', width: 280, sortable: true },
        { text: 'Fecha de registro', value: 'created', align: 'end', width: 280, sortable: true },
        { text: 'Fecha del evento', value: 'date', align: 'end', width: 180, sortable: true },
        { text: 'Error', value: 'event_type' },
        { text: 'POS', value: 'pos' },
        { text: 'Tienda', value: 'branch' },
        { text: 'Estado', value: 'reviewed', width: 100 }
        // { text: '', value: 'actions', align: 'end', width: 60 }
      ],
      current: {},
      dialogExport: false,
      dialogExportProgress: false,
      resetCheckbox: 0,
      isSelected: null,
      selected: [],
      event_types: [
        {
          id: 1,
          name: 'Error Generating document'
        },
        {
          id: 2,
          name: 'Lack of space'
        },
        {
          id: 3,
          name: 'No communication'
        },
        {
          id: 4,
          name: 'No communication'
        }
      ],
      emails: [],
      emailsList: [],
      env: process.env?.VUE_APP_MODE ?? 'ax'
    }),
    computed: {
      ...mapState({
        count: state => state.logs.event_logCount,
        logList: state => state.logs.event_logList
      }),
      nameEventType () {
        return (type = null) => {
          return this.event_types.find(({id}) => id === type)?.name ?? '-'
        }
      },
      heightTable () {
        if (this.$vuetify.breakpoint.height <= 1200 && this.logList.length >= 16 && this.logList.length <= 20) return 'calc(100vh - 210px)'
        if (this.logList.length > 20) return 'calc(100vh - 165px)'
        if (this.logList.length < 16) return 'auto'
        return 'calc(100vh - 275px)'
      }
    },
    watch: {
      selected (val) {
        if (val.length !== this.logList.length) this.resetCheckbox += 1
      },
      activeDialogExport (val) {
        this.dialogExport = val
      },
      markSolved (val) {
        if (val) {
          if (this.selected.length) this.updateReviewed()
          this.$emit('update:markSolved', false)
        }
      }
    },
    methods: {
      toggleAll () {
        this.selected = !this.isSelected ? [] : this.logList
      },
      handleExport () {
        this.dialogExport = false
        this.dialogExportProgress = true
        this.$emit('update:activeDialogExport', false)
      },
      setEmail (e) {
        let array = []
        this.emailsList = []

        if (e?.length) {
          let elm = e.replace(/ /g, ',')
          array = [...array, ...elm.split(',')]
          array = array.filter((item) => Boolean(item))

          if (array.length === 1) {
            if (REGEX.test(e)) {
              this.emailsList.push(e)
              this.emailsList = [...new Set([...this.emailsList])]
            }
          } else {
            this.key += 1
            this.emails = [...this.emails, ...array.filter(item => REGEX.test(item))]
            this.emails = [...new Set(this.emails)]
          }
        }
      },
      remove (item) {
        const index = this.emails.indexOf(item)
        if (index >= 0) {
          this.emails.splice(index, 1)
        }
      },
      sendEmails () {
        this.emails = [...this.emails.filter(item => REGEX.test(item))]
        this.$store.dispatch('logs/CREATE', {
          resource: `send_events`,
          payload: {
            ids: this.selected.map((item) => item.id),
            emails: this.emails
          }
        })
        .then(() => {
          this.selected = []
          this.emails = []
          this.$dialog.message.info('El envío de LOG se realizó con éxito')
        })
        .catch((error) => {
          this.$dialog.message.error(this.selected.length ? this.parseErrors(error.response.data) : 'Debe seleccionar al menos un POS')
        })
        .finally(() => {
          this.$emit('update:dialogSendEmail', false)
        })
      },
      async updateReviewed () {
        const selectedLogsLength = this.selected.length
        await Promise.all(this.selected.map(async ({id, reviewed}) => {
          try {
            this.$store.dispatch('logs/UPDATE', {
              resource: 'event_log',
              id: id,
              payload: {
                reviewed: !reviewed
              }
            })
            .then(() => {
              this.dialogSupplier = false
              this.$dialog.message.info(selectedLogsLength === 1 ? 'El evento ha sido actualizado' : 'Los eventos has sido actualizados')
            })
            .catch(() => {
              this.$dialog.message.error(selectedLogsLength === 1 ? 'Error al actualizar el evento' : 'Error al actualizar los eventos')
            })
          } catch (e) {
            return []
          }
        }))
        this.selected = []
        this.resetCheckbox += 1
        this.$router.replace(`/logs`).catch(() => {})
      },
      download (item = null) {
        if (item.data) {
          const content = Buffer.from(item.data, 'base64')
          saveAs(new Blob([content], {type: 'application/pdf'}), `${item.id}.pdf`)
          this.$dialog.message.info('La exportación se realizó con éxito')
        } else {
          this.$dialog.message.error('Ha ocurrido un error al descargar el pdf')
        }
      }
    }
  }
  </script>