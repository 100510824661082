<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="LOGS" :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true" :active.sync="activeFilter">
      <template v-slot:main>
        <v-btn class="mr-2" @click="dialogSendEmail = true" outlined :disabled="count === 0"><v-icon left>mdi-email</v-icon>Enviar por email</v-btn>
          <!-- <v-btn class="mr-2" @click="dialogExport = true" outlined :disabled="count === 0"><v-icon left size="20">mdi-export-variant</v-icon>Exportar</v-btn> -->
        <v-btn @click="markSolved = true" color="blue-500" :disabled="count === 0 || list.every(({reviewed}) => reviewed === false)"><v-icon left>mdi-check</v-icon>Marcar como resuelto</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="px-0">
      <logs-list :loading="loading" @activeBtnFilter="activeFilter = true" :markSolved.sync="markSolved" :dialogSendEmail.sync="dialogSendEmail" @closeSendEmail="dialogSendEmail=false" />
    </v-col>

    <!-- dialog export -->
    <v-dialog v-model="dialogExport" width="700" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="transparent" flat height="48">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Exportación de documentos</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-4">
                <v-btn @click="dialogExport=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="v-list-form pa-0 transparent">
            <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title class="grey-500--text body-1 font-weight-bold">Enviar a</v-list-item-title>
                <v-list-item-subtitle>
                  El archivo exportado, se enviara al email [email de la cuenta de usuario]
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title class="grey-500--text body-1 font-weight-bold">Prepara tus archivos para la exportación</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title class="grey-500--text body-1">
                  <span class="font-weight-bold">Exportando:</span>  registros
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="grey-500--text body-1 font-weight-bold">Exportar como:</v-list-item-title>
                <v-list-item-subtitle class="pb-16">
                  <v-radio-group class="mx-0 mt-0" v-model="type" column absolute style="position:absolute" dense hide-details>
                    <v-radio label="pdf" value="pdf"></v-radio>
                    <v-radio label="txt" value="txt"></v-radio>
                  </v-radio-group>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="dialogExport=false" :ripple="false">Cancelar</v-btn>
          <v-btn color="blue-500" @click="createExport" :ripple="false">Exportar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog export -->
  </v-row>
 </template>
 <script>
 import { mapState } from 'vuex'
 import { saveAs } from 'file-saver'
 import MiniHeader from '@/components/commons/MiniHeader'
 import LogsList from '@/modules/pos/logs/components/LogsList'
 import ListViewMixin from '@/mixins/ListViewMixin'
 export default {
   components: {
     LogsList,
     MiniHeader
   },
   mixins: [
     ListViewMixin
   ],
   data: () => ({
    activeFilter: false,
    scroll: 0,
     markSolved: false,
     dialogSendEmail: false,
     activeDialogExport: false,
     dialogExport: false,
     loading: false,
     type: '',
     breadcrumbs: {
      main: 'POS',
      children: [
        {
          text: 'LOGS'
        }
      ]
    }
   }),
   computed: mapState({
     list: state => state.logs.event_logList,
     count: state => state.logs.event_logCount
   }),
   created () {
    this.getList()
    if (this.$route.query.branch) {
      this.breadcrumbs.children = [
        { text: 'Tienda', route: { name: 'BranchRetrieveUpdate', params: { id: this.$route.query.branch }} },
        { text1: 'LOG'}
      ]
    }
  },
   methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
     getList () {
      this.loading = true
      this.$store.dispatch('logs/LIST', {
        resource: 'event_log',
        query: {
          ...this.$route.query
        }
      })
      .then(() => {
        this.loading = false
      })
     },
     createExport () {
      this.dialogExport = false
      this.loading = true
      this.$store.dispatch('logs/LIST', {
        resource: 'exports/event_log',
        query: {
          ...this.$route.query,
          extension: this.type
        },
        loader: true,
        responseType: 'arraybuffer'
      })
      .then(async (response) => {
        const blob = await new Blob([response.data], {type: "application/zip"})
        saveAs(blob, 'documentos.zip')
        this.$dialog.message.info('La exportación se realizó con éxito')
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error en la exportación')
      })
      .finally(() => {
        this.loading = false
      })
    }
   }
 }
 </script>